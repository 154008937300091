import React from 'react'
//react hook for functional component
import { useContext } from 'react'
import { CarContext } from '../context'
import Title from './Title'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import { FormControl } from '@mui/material';

//get unique values: items = cars array, value =car type
const uniqueValues = (items, value) => {
  return [...new Set(items.map(item => item[value]))]
}

const CarsFilter = ({ cars }) => {
  
  const context = useContext(CarContext)
  const {
    handleChange,
    type,
    carMake,
    price,
    minPrice,
    maxPrice,
    minSize,
    maxSize,
    sportsPackage,
    gps
  } = context;

  const [valuePrice, setValuePrice] = React.useState([minPrice, maxPrice]);

  const handleChangePrice = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValuePrice([Math.min(newValue[0], valuePrice[1] - 10000), valuePrice[1]]);
    } else {
      setValuePrice([valuePrice[0], Math.max(newValue[1], valuePrice[0] + 10000)]);
    }
    handleChange(event);
  };

  // get unique types
  let types = uniqueValues(cars, 'type');

  // add 'all' type to array
  types = ['all', ...types];


  //JSX MARKUP for types of cars
  types = types.map((item, index) => {
    return <MenuItem value={item} key={index}>{item}</MenuItem>;
  });

  //JSX MARKUP for cars capacity(car make-manufacturer)
  let manufacturers = uniqueValues(cars, 'carMake');
  // add 'all' type to array
  manufacturers = ['all', ...manufacturers];

  manufacturers = manufacturers.map((manufacturer, index) => {
    return <MenuItem key={index} value={manufacturer}>{manufacturer}</MenuItem>;
  })

  return (
    <section className="filter-container">
      <Title title="Search Cars" />
      <form className="filter-form">
        {/* select type input*/}
        <div className="form-group">
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="type"
              name="type"
              value={type}
              onChange={handleChange}
              label="Car type"
              className="form-control"
            >
              {types}
            </Select>
          </FormControl>
        </div>
        {/* end of select type */}
        {/* capacity input*/}
        <div className="form-group">
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="carMake"
              name="carMake"
              value={carMake}
              onChange={handleChange}
              label="Brand"
              className="form-control"
            >
              {manufacturers}
            </Select>
          </FormControl>
        </div>
        {/* end of capacity select type */}
        {/* cars price select type */}
        <div className="form-group">
          <label htmlFor="price">
            Price Range <br/> 
            <b>E{price[0] ? price[0] : valuePrice[0] } - E{price[1] ? price[1] : valuePrice[1] } </b>
            {/* <input onChange={handleChange} type="range" name="price" id="price" min={minPrice} max={maxPrice} className="form-control" /> */}
          </label>
          <Slider
            getAriaLabel={() => 'Minimum distance'}
            value={valuePrice}
            onChange={handleChangePrice}
            valueLabelDisplay="auto"
            type="range"
            name="price"
            id="price"
            min={minPrice} 
            max={maxPrice}
            disableSwap
          />
        </div>
        {/* end of cars price select type */}
        {/* cars size select type */}
        {/* <div className="form-group">
          <label htmlFor="size">fuel tank capacity</label>
          <div className="size-inputs">
            <input onChange={handleChange} type="number" name="minSize" id="size" value={minSize} className="size-input" />
            <input onChange={handleChange} type="number" name="maxSize" id="size" value={maxSize} className="size-input" />
          </div>
        </div> */}
        {/* end of cars size select type */}
        {/* end of cars extras select type */}
        {/* <div className="form-group">
          <div className="single-extra">
            <input onChange={handleChange} type="checkbox" name="sportPackage" id="sportPackage" checked={sportsPackage} />
            <label htmlFor="sportPackage">Sport package</label>
          </div>
          <div className="single-extra">
            <input onChange={handleChange} type="checkbox" name="gps" id="gps" checked={gps} />
            <label htmlFor="gps">gps</label>
          </div>

        </div> */}
        {/* end of cars extras select type */}
      </form>
    </section>
  )
}

export default CarsFilter
