import React, { Component } from 'react'
import Title from './Title';
import { FaCar, FaChartLine, FaBusinessTime, FaRoad } from 'react-icons/fa';
import PhoneIcon from '@mui/icons-material/Phone';

export default class Contacts extends Component {

  state = {
    services: [
      {
        icon: <PhoneIcon />,
        title: 'Lorem ipsum dolor',
        info: '7632 2409'

      },
      {
        icon: <PhoneIcon />,
        title: 'Lorem ipsum dolor',
        info: '7602 5927'

      }
    ]
  }

  render() {

    let { services } = this.state;

    const servicesList = services.map((service, index) => {
      let { icon, title, info } = service;

      return (
        <article key={index} className="contacts">
          <span>{icon}</span>
          <span>{info}</span>
        </article>
      )
    });

    return (
      <section className="services">
        <div className="services-center">
          {servicesList}
        </div>
      </section>
    )
  }
}
