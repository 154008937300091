import { Grid } from '@mui/material';
import React from 'react';
import FrontImage from '../images/front.jpg';

const Location = () => {
  return (
    <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
            <img src={FrontImage} alt="Front Image" style={{width: '600px', margin: 'auto auto'}}/>
        </Grid>
        <Grid item xs={12} md={6}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14304.610329465922!2d31.13015136977539!3d-26.321558300000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd5e86cf893403672!2sCar%20Hire%20Zone%20%7C%20Car%20Sales%20%7C%20Tyre%20Shop!5e0!3m2!1sen!2s!4v1661933864043!5m2!1sen!2s" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Grid>
    </Grid>
  )
}


export default Location;