import React from 'react'
import HeroBackground from '../Components/HeroBackground'
import Banner from '../Components/Banner'
import { Link } from 'react-router-dom'
import CarsContainer from '../Components/CarsContainer'
import Navbar from '../Components/Navbar'
import Contacts from '../Components/Contacts';
import Location from '../Components/Location'

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <HeroBackground hero="carSalesHero">
        <Banner title='Contact Us'>
          <Link to='/' className="btn-primary">
            Return home
        </Link>
        </Banner>
      </HeroBackground>
      <Contacts />
      <Location />
    </>
  )
}

export default ContactUs;