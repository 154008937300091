import React from 'react'
import HeroBackground from '../Components/HeroBackground'
import Banner from '../Components/Banner'
import { Link } from 'react-router-dom'
import CarsContainer from '../Components/CarsContainer'
import Navbar from '../Components/Navbar'

const Cars = () => {
  return (
    <>
      <Navbar />
      <HeroBackground hero="tyresHero">
        <Banner title='Tyre Zone'>
          <Link to='/' className="btn-primary">
            Return home
        </Link>
        </Banner>
      </HeroBackground>
      <CarsContainer />
    </>
  )
}

export default Cars;