import React from 'react';
import './Styles.css';
import Home from './Pages/Home';
import CarRentals from './Pages/CarRentals';
import CarSales from './Pages/CarSales';
import TyreZone from './Pages/TyreZone';
import SingleCar from './Pages/SingleCar';
import ErrorPage from './Pages/ErrorPage';
import ContactUs from './Pages/ContactUs';
import { Route, Switch } from 'react-router-dom';



function App() {
  return (

    <>
      <Switch>

        <Route exact path='/' component={Home} />
        <Route exact path='/car-sales' component={CarSales} />
        <Route exact path='/car-rentals' component={CarRentals} />
        <Route exact path='/tyre-zone' component={TyreZone} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route exact path='/car/:slug' component={SingleCar} />
        <Route component={ErrorPage} />

      </Switch>
    </>


  );
}

export default App;
