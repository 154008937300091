import React from 'react'
import HeroBackground from '../Components/HeroBackground'
import Banner from '../Components/Banner'
import { Link } from 'react-router-dom'
import CarsContainer from '../Components/CarsContainer'
import Navbar from '../Components/Navbar'
import Services from '../Components/Services';
// import FeaturedCars from './FeaturedCars';

const CarRentals = () => {
  return (
    <>
      <Navbar />
      <HeroBackground hero="carRentalsHero">
        <Banner title='Car Hire Zone'>
          <Link to='/' className="btn-primary">
            Return home
          </Link>
        </Banner>
      </HeroBackground>
      <Services />
      {/* <FeaturedCars /> */}
      <CarsContainer />
    </>
  )
}

export default CarRentals;