import React from 'react'
import HeroBackground from '../Components/HeroBackground'
import Banner from '../Components/Banner'
import { Link } from 'react-router-dom'
import CarsContainer from '../Components/CarsContainer'
import Navbar from '../Components/Navbar'
import Services from '../Components/Services';

const CarSales = () => {
  return (
    <>
      <Navbar />
      <HeroBackground hero="carSalesHero">
        <Banner title='Car Sales'>
          <Link to='/' className="btn-primary">
            Return home
        </Link>
        </Banner>
      </HeroBackground>
      <Services />
      <CarsContainer />
    </>
  )
}

export default CarSales;